.common-layout {

  .page-container {
    min-height: 100vh;
    padding-left: $side-nav-full-width;
    @include transition(all 0.2s ease);

    // @include screen-laptop-only {
    //     padding-left: $side-nav-width-laptop;
    // }

    @include screen-tablet {
      padding-left: 0px;
    }

    .main-content {
      padding: calc(#{$header-height} + 10px) 10px 0 10px;
      min-height: calc(100vh - #{$footer-height});

      @include screen-mobile {
        padding: calc(#{$header-height} + #{$layout-content-gutter-sm}) $layout-content-gutter-sm $layout-content-gutter-sm $layout-content-gutter-sm;
      }

      .main-content-header {
        @include screen-mobile-above {
          display: flex;
          align-items: center;
        }

        padding-left: 5px;
        margin-bottom: $layout-content-gutter;

        .page-title {
          margin-bottom: 0px;
          margin-right: 20px;
          color: $gray;
          font-size: 17px;
          line-height: 1;

          @include screen-mobile {
            margin-bottom: 20px;
            margin-right: 0px;
          }
        }
      }
    }
  }

  .main-content {
    position: relative;
  }

  .omi-button {
    text-align: center;
    justify-content: center;
    align-items: center;
    width: 54px;
    height: 54px;
    position: fixed;
    right: 15px;
    background: #15397f;
    border-radius: 100%;
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.35);
    z-index: 100;
    color: #fff !important;
    bottom: 38px;
    -webkit-transition: all 0.25s ease;
    -moz-transition: all 0.25s ease;
    transition: all 0.25s ease;

    // position: fixed;
    // bottom: 35px;
    // right: 80px;
    // height: 60px;
    // width: 60px;
    // border-radius: 30px;
    // background-color: #02acf7;
    // display: flex;
    // justify-content: center;
    // align-items: center;
    // z-index: 2147483647;
  }

  .omi-button-ringing {
    animation: pulse_button 0.5s infinite;
    // animation: shake 1.0s infinite;
  }

  @keyframes pulse_button {
    0% {
      box-shadow: 0 0 0 0 rgba(34, 188, 250, 0.7);
      /* Màu xanh lá cây */
    }

    70% {
      box-shadow: 0 0 0 15px rgba(34, 188, 250, 0);
      /* Tỏa nhỏ hơn */
    }

    100% {
      box-shadow: 0 0 0 0 rgba(34, 188, 250, 0);
    }
  }

  @keyframes shake {
    0% {
      box-shadow: 0 0 0 0 rgba(34, 188, 250, 0.7);
      transform: rotate(0deg);
      -webkit-transform: rotate(0deg);
    }

    12% {
      transform: rotate(7deg);
      -webkit-transform: rotate(7deg);
    }

    25% {
      transform: rotate(15deg);
      -webkit-transform: rotate(15deg);
    }

    37% {
      transform: rotate(7deg);
      -webkit-transform: rotate(7deg);
    }

    50% {
      transform: rotate(0deg);
      -webkit-transform: rotate(0deg);
    }

    70% {
      box-shadow: 0 0 0 15px rgba(34, 188, 250, 0);
      /* Tỏa nhỏ hơn */
    }

    62% {
      transform: rotate(-7deg);
      -webkit-transform: rotate(-7deg);
    }

    75% {
      transform: rotate(-15deg);
      -webkit-transform: rotate(-15deg);
    }

    87% {
      transform: rotate(-7deg);
      -webkit-transform: rotate(-7deg);
    }

    100% {
      box-shadow: 0 0 0 0 rgba(34, 188, 250, 0);
      transform: rotate(0deg);
      -webkit-transform: rotate(0deg);
    }
  }

  .omi-button:hover {
    opacity: 0.8;
    cursor: pointer;
  }

  .omi-view-isDragAndDrop {
    transform: translate(-50%, -50%);
    position: fixed;
    top: 50%;
    left: 50%;
  }

  .omi-view {
    position: fixed;
    transform: translate(-50%, -50%);
    top: 50%;
    left: 50%;
  }

  .myhub-view {
    // position: fixed;
    // top: 30%;
    // /* Đặt top ở giữa */
    // left: 50%;
    // /* Đặt left ở giữa */
    // transform: translate(-50%, -50%);
    //   bottom: 15px;
    //   right: 150px;
  }

  .page-header {
    // margin-left: -$layout-content-gutter;
    margin-right: -$layout-content-gutter;
    margin-top: -$layout-content-gutter;
    margin-bottom: $layout-content-gutter;
    background-color: $white;
    // border-bottom: 1px solid $border-color;
    padding: 5px 10px;
    border: 1px solid #ddd;

    &.has-tab {
      border-bottom: 0px;
      margin-bottom: 0px;
      padding-bottom: 1px;
      margin-top: 0;
    }

    @include screen-mobile {
      margin-left: -$layout-content-gutter-sm;
      margin-right: -$layout-content-gutter-sm;
      margin-top: -$layout-content-gutter-sm;
      margin-bottom: $layout-content-gutter-sm;
    }

    h4,
    h2,
    h3 {
      margin-bottom: 0;
    }
  }

  .page-header-tab {
    // margin-left: -$layout-content-gutter;
    margin-right: -$layout-content-gutter;

    @include screen-mobile {
      margin-left: -$layout-content-gutter-sm;
      margin-right: -$layout-content-gutter-sm;
    }

    .ant-tabs-nav {
      background-color: $white;
      padding-left: $layout-content-gutter;
      padding-right: $layout-content-gutter;
      margin-bottom: $layout-content-gutter;
      border-left: 1px solid #ddd;

      @include screen-mobile {
        padding-left: $layout-content-gutter-sm;
        padding-right: $layout-content-gutter-sm;
        margin-bottom: $layout-content-gutter-sm;
      }
    }

    .ant-tabs-content {

      .ant-tabs-tabpane {
        padding-left: $layout-content-gutter;
        padding-right: $layout-content-gutter;

        @include screen-mobile {
          padding-left: $layout-content-gutter-sm;
          padding-right: $layout-content-gutter-sm;
        }
      }
    }
  }

  &.is-folded {
    .main-content {
      //padding: calc(50px + 10px) 0px 0 0;
    }

    .page-container {
      padding-left: 0px;
    }
  }

  &.is-expand {
    .main-content {
      //padding: calc(50px + 10px) 0px 0 0;
    }

    .page-container {
      padding-left: $side-nav-full-width;
    }
  }
}