.header {
  background: #fff;
  height: $header-height;
  border: 1px solid $border-color;
  position: fixed;
  left: 10px;
  top: 0px;
  width: calc(100% - 130px);
  z-index: 999;
  display: flex;
  margin-left: 0px;
  border-radius: 0px;
  border-top: 0;
  left: 120px;

  // margin-left: $side-nav-full-width;
  .logo {
    width: $side-nav-width;
    border-right: 1px solid $border-color;
    background-color: #2464D4;
    @include transition(all 0.2s ease);

    @include screen-laptop-only {
      width: $side-nav-width-laptop;
    }

    @include screen-tablet {
      display: none;
    }

    >a {
      display: block;
      text-align: center;
      color: #111;
      font-size: 20px;
      margin: 10px;
      font-weight: bold;
    }

    .logo-fold {
      display: none;
    }

    &.logo-white {
      display: none;
    }
  }

  .nav-wrap {
    flex: 1;
    display: flex;
    justify-content: space-between;



    .nav-left,
    .nav-right {
      list-style-type: none;
      padding-left: 0px;
      margin-bottom: 0px;
      align-items: center;
      display: flex;
      color: #111;

      li {

        >a,
        nz-dropdown>a {
          padding: 5px 15px;
          font-size: 18px;
          color: #111;
          display: block;
          @include transition(background-color 0.3s);
          border-radius: $border-radius;

          &:hover,
          &:focus,
          &:active {
            color: #111;
            background-color: rgba($brand-primary, 0.1);

            .ant-badge {
              color: #111;
            }
          }

          .ant-badge {
            color: #111;
            font-size: 18px;
          }
        }
      }

      ;

      .ant-typography-copy {
        color: #111;
      }
    }

    .nav-left {
      margin-left: 10px;
    }

    .nav-right {
      margin-right: 10px;
    }

    // .desktop-toggle {
    //   @include screen-tablet {
    //     display: none;
    //   }
    // }

    .mobile-toggle {
      @include screen-tablet-above {
        display: none;
      }
    }
  }

  .ant-dropdown-link,
  .ant-dropdown-trigger {
    .anticon:not(.anticon-ellipsis) {
      font-size: 18px;
      transform: none;
    }
  }

  &.header-folded {

    .logo {
      width: $side-nav-folded;
    }
  }
}

.pop-notification {
  width: 350px;
}

.is-folded {
  @include screen-tablet-above {
    .header {

      .logo {
        width: $side-nav-folded;

        img {
          display: none;
        }

        .logo-fold {
          display: block;
        }
      }
    }
  }
}

.is-primary,
.is-secondary,
.is-success,
.is-danger,
.is-custom {

  .header {
    border-bottom: 0px;

    .logo {
      border-color: transparent;

      &.logo-dark {
        display: none;
      }

      &.logo-white {
        display: block;
      }
    }

    .nav-left,
    .nav-right {

      li {

        >a,
        nz-dropdown>a {
          color: rgba($white, 0.8);

          &:hover,
          &:focus,
          &:active {
            color: $white;
            background-color: rgba($white, 0.15);

            .ant-badge {
              color: $white;
            }
          }

          .ant-badge {
            color: rgba($white, 0.8);
          }
        }
      }
    }
  }
}

@each $theme in $theme-color-loop {
  .#{nth($theme, 1)} {

    .header {
      background-color: #{nth($theme, 2)};
    }
  }
}

.is-folded {
  .header {
    left: 10px;
    width: calc(100% - 20px);
  }
}

@media screen and (max-width: 1000px) {
  .is-expand {
    .header {
      left: calc($side-nav-width + 10px);
    }
  }
}

.collapse-expried {
  background-color: #ffe5e7;
  height: 40px;
  width: 40px;
  border-radius: 20px;
  color: #f83938;
  font-weight: 700;
  display: flex;
  justify-content: center;
  align-items: center;
}