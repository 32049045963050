.footer {
   
    font-size: 90%;
    

    .footer-content {
        display: flex;
        height: $footer-height;
        align-items: center;
        border-top: 1px solid $border-color;
        flex-direction: row-reverse;
    }

    .huongdansudung{
        color: #1E86FF !important;

    }
    a.huongdansudung:hover{
        color: #1E86FF !important;
    }

    .download-ultraview{
        color: white !important;
        background: #0D9F00;
        padding: 5px;
        border-radius: 5px;

    }
    a.download-ultraview:hover{
        color: white !important;
    }
}