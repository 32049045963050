.ant-select {
  color: $input-color;
  font-size: $font-size-base;
  line-height: $line-height-base;

  &:not(.ant-select-disabled) {
    &:hover {

      .ant-select-selector {
        border-color: $color-button-sb-border-color-radio;
        border-right-width: 1px !important;
      }
    }
  }
}

.ant-select-single {

  &:not(.ant-select-customize-input) {
    .ant-select-selector {
      background-color: $white;
      border: 1px solid $input-border-color;
      border-radius: $input-border-radius;
      height: $select-height + 0.0005rem;
      padding: 0 11px;

      .ant-select-selection-search-input {
        height: $select-height;
      }
    }
  }

  &:not(.ant-select-disabled) {
    &:hover {
      .ant-select-selector {
        border-color: $color-button-sb-border-color-radio;
      }
    }
  }

  .ant-select-selector {

    .ant-select-selection-item,
    .ant-select-selection-placeholder {
      line-height: $select-height;
    }
  }

  &.ant-select-sm {
    &:not(.ant-select-customize-input) {
      .ant-select-selector {
        // height: $select-height-sm + 0.0005rem;
        height: $n-all-size-sm;
      }

      &:not(.ant-select-customize-input) {
        .ant-select-selection-search-input {
          // height: $select-height-sm;
          height: $n-all-size-sm;
        }
      }

      .ant-select-selector {

        .ant-select-selection-item,
        .ant-select-selection-placeholder {
          // line-height: $select-height-sm;
          line-height: $n-all-size-sm;
        }
      }
    }
  }

  &.ant-select-lg {
    &:not(.ant-select-customize-input) {
      .ant-select-selector {
        height: $select-height-lg + 0.0005rem;
      }

      &:not(.ant-select-customize-input) {
        .ant-select-selection-search-input {
          height: $select-height-lg;
        }
      }

      .ant-select-selector {

        .ant-select-selection-item,
        .ant-select-selection-placeholder {
          line-height: $select-height-lg;
        }
      }
    }
  }
}

.ant-select-multiple {

  .ant-select-selector {
    background-color: $white;
    border: 1px solid $input-border-color;
    border-radius: 0.25rem !important;
    padding: 1px $select-tag-margin-y;
    // min-height: $select-height + 0.0005rem;
    // min-height: $select-height + 0.0005rem;
    min-height: $n-all-size-sm;
  }

  .ant-select-selection-item {
    // height: $select-tag-height;
    // line-height: $select-tag-height - 0.0005rem;
    // margin-top: $select-tag-margin-y;
    // margin-right: $select-tag-margin-x;
    // margin-bottom: $select-tag-margin-y;

    height: 22px;
    line-height: 22px;
    margin-top: 3px;
    margin-right: $select-tag-margin-x;
    margin-bottom: 3px;
    padding: 0 4px 0 8px;
    background: $select-tag-color;
    border: 1px solid #f0f0f0;
    border-radius: $border-radius;
  }

  &.ant-select-sm {

    .ant-select-selector {
      // min-height: $select-height-sm + 0.0005rem;
      min-height: $n-all-size-sm;
    }

    .ant-select-selection-item {
      height: $select-tag-height-sm;
      line-height: $select-tag-height-sm - 0.0005rem;
    }
  }

  &.ant-select-lg {

    .ant-select-selector {
      height: $select-height-lg + 0.0005rem;
    }

    .ant-select-selection-item {
      height: $select-tag-height-lg;
      line-height: $select-tag-height-lg - 0.0005rem;
    }
  }
}

.ant-select-focused {
  &.ant-select-single {
    &:not(.ant-select-customize-input) {

      .ant-select-selector {
        border-color: $color-button-sb-border-color-radio;
        box-shadow: 0 0 0 2px rgba($color-button-sb-border-color-radio, 0.2);
      }
    }
  }

  &.ant-select-multiple {
    .ant-select-selector {
      border-color: $color-button-sb-border-color-radio;
      box-shadow: 0 0 0 2px rgba($color-button-sb-border-color-radio, 0.2);
    }
  }
}

.ant-select-item {
  color: $body-color;
  font-weight: $font-weight-normal;
  font-size: $font-size-base;
  line-height: $select-item-line-height;
}

.ant-select-item-option-disabled {
  color: rgba($body-color, 0.4)
}

.ant-select-item-option-selected {
  &:not(.ant-select-item-option-disabled) {
    color: $brand-primary;
    font-weight: $font-weight-semibold;
    background-color: transparent;

    .ant-select-item-option-state {
      color: $brand-primary;
    }
  }
}

.ant-select-item-option-active {
  &:not(.ant-select-item-option-disabled) {
    background: $color-button-sb;
    color: white;
  }
}

.ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-cu
stomize-input) .ant-select-selector {
  border-color: $color-button-sb-border-color-radio;
}
.ant-select .ant-select-selector:has(.ant-select-selection-item) {
  border: 1px solid #fac4de;
}
// n responsive
@media (max-width: 1366px) {
  .ant-select-single {
    &.ant-select-sm {
      &:not(.ant-select-customize-input) {
        .ant-select-selector {
          // height: $select-height-sm + 0.0005rem;
          height: $n-all-size-sm;
          font-size: $n-all-font-size-xs;
        }

        &:not(.ant-select-customize-input) {
          .ant-select-selection-search-input {
            font-size: $n-all-font-size-xs;
            height: $n-all-size-sm;
          }
        }

        .ant-select-selector {

          .ant-select-selection-item,
          .ant-select-selection-placeholder {
            font-size: $n-all-font-size-xs;
            line-height: $n-all-size-sm;
          }
        }
      }
    }
  }

  .ant-select-multiple {
    .ant-select-selector {
      background-color: $white;
      border: 1px solid $input-border-color;
      border-radius: $input-border-radius;
      padding: 1px $select-tag-margin-y;
      // min-height: $select-height + 0.0005rem;
      // min-height: $select-height + 0.0005rem;
      min-height: $n-all-size-sm;
      font-size: $n-all-font-size-xs;
    }
  }
}