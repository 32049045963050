.ant-menu {
  font-size: $font-size-base;
  line-height: $line-height-base;
  // font-weight: $font-weight-semibold;
  color: $menu-text;

  &:not(.ant-menu-horizontal) {
    .ant-menu-item-selected {
      // background: #C60000;
      background: linear-gradient(to right, #FD65AF, #FE8377);
      border: 2px solid #FD65AF;
      border-radius: 8px;

      a {
        color: $white !important;
      }
    }
  }

  &:not(.ant-menu-dark) {
    a {
      color: $menu-text;

      &:hover,
      &:focus {
        text-decoration: none;
      }

      &:hover {
        color: $menu-text;
      }
    }
  }

  &.ant-menu-dark {
    a {
      color: $menu-dark-text;
    }

    .ant-menu-item-selected {
      background-color: transparent;
      color: $white;
    }
  }
}

.ant-menu-sub {
  font-weight: $font-weight-normal;

  &.ant-menu-inline {
    background: rgba(246, 246, 246, 1);
    padding: 0 10px;
    padding-bottom: 10px;
    border-radius: 8px;
  }
}

.ant-menu-submenu,
.ant-menu-submenu-inline {
  transition: none;

}

.ant-menu-horizontal {
  line-height: 2.8125rem;
  border-bottom-color: $border-color;

  >.ant-menu-item-active,
  >.ant-menu-item-open,
  >.ant-menu-item-selected,
  >.ant-menu-submenu-active,
  >.ant-menu-submenu-open,
  >.ant-menu-submenu-selected {
    border-bottom: 0px;
    color: $brand-primary;
  }

  >.ant-menu-item,
  >.ant-menu-submenu {
    border-bottom: 0px;

    &:hover {
      border-bottom: 0px;
      color: $brand-primary;
    }
  }
}

.ant-menu-inline,
.ant-menu-vertical,
.ant-menu-vertical-left {
  border-right-color: transparent;
}

.ant-menu-inline>.ant-menu-item,
.ant-menu-inline>.ant-menu-submenu>.ant-menu-submenu-title,
.ant-menu-vertical-left>.ant-menu-item,
.ant-menu-vertical-left>.ant-menu-submenu>.ant-menu-submenu-title,
.ant-menu-vertical-right>.ant-menu-item,
.ant-menu-vertical-right>.ant-menu-submenu>.ant-menu-submenu-title,
.ant-menu-vertical>.ant-menu-item,
.ant-menu-vertical>.ant-menu-submenu>.ant-menu-submenu-title {
  // line-height: $menu-spacing-y;
  // height: $menu-spacing-y;
  line-height: $n-all-size-sm;
  height: $n-all-size-sm;
}

.ant-menu-vertical .ant-menu-item,
.ant-menu-vertical-left .ant-menu-item,
.ant-menu-vertical-right .ant-menu-item,
.ant-menu-inline .ant-menu-item,
.ant-menu-vertical .ant-menu-submenu-title,
.ant-menu-vertical-left .ant-menu-submenu-title,
.ant-menu-vertical-right .ant-menu-submenu-title,
.ant-menu-inline .ant-menu-submenu-title {
  line-height: $menu-spacing-y;
  height: $menu-spacing-y;
}

.ant-menu-item {
  >a {
    color: $menu-text;
  }
}

.ant-menu-item-group-title {
  padding: 0.75rem 1.125rem;
  color: $menu-text;
  font-size: $font-size-base;
  line-height: $line-height-base;
  font-weight: $font-weight-light;
  margin-top: 0.9375rem;
}

.ant-menu-item,
.ant-menu-submenu-title {
  &:active {
    color: $menu-text;
    background: rgba(233, 233, 233, 1);

  }
}

.ant-menu-inline,
.ant-menu-vertical,
.ant-menu-vertical-left,
.ant-menu-vertical-right {
  .ant-menu-item {
    &:after {
      // border-right: 3px solid $brand-primary;
      border: none;
    }
  }
}

.ant-menu-submenu-inline,
.ant-menu-submenu-vertical-left,
.ant-menu-submenu-vertical-right,
.ant-menu-submenu-vertical {
  >.ant-menu-submenu-title {
    .ant-menu-submenu-arrow {

      &:after,
      &:before {
        content: "";
        position: absolute;
        background: transparent;
        background-image: linear-gradient(to right, $menu-text, $menu-text);
        border-radius: 2px;
      }
    }

    // &:hover {
    //     .ant-menu-submenu-arrow {

    //         &:after,
    //         &:before {
    //             background: linear-gradient(to right, $brand-primary, $brand-primary);
    //         }
    //     }
    // }

    &.ant-menu-submenu-open {
      .ant-menu-submenu-arrow {

        &:after,
        &:before {
          background: linear-gradient(to right, $brand-primary, $brand-primary);
        }
      }
    }
  }
}

.ant-menu-dark {
  background-color: $menu-dark-bg;
  color: $menu-dark-text;

  .ant-menu-item:hover,
  .ant-menu-item-active,
  .ant-menu-submenu-active,
  .ant-menu-submenu-open,
  .ant-menu-submenu-selected,
  .ant-menu-submenu-title:hover {
    color: $white;
    background-color: transparent;
  }

  a {
    color: $menu-dark-text;
  }

  &:not(.ant-menu-horizontal) {
    .ant-menu-item-selected {
      background-color: transparent;

      >a {
        color: $white;
      }
    }
  }

  .ant-menu-item,
  .ant-menu-item-group-title {
    color: $menu-dark-text;

    >a {
      color: $menu-dark-text;
    }
  }

  .ant-menu-sub {
    background-color: $menu-dark-bg;
    color: $menu-dark-text;

    .ant-menu-submenu-title {
      .ant-menu-submenu-arrow {

        &:after,
        &:before {
          background: $white;
        }
      }
    }
  }

  .ant-menu-submenu-title {
    .ant-menu-submenu-arrow {

      &:after,
      &:before {
        background: $white;
      }
    }
  }

  .ant-menu-inline {
    &.ant-menu-sub {
      background-color: $menu-dark-bg-sub;
      box-shadow: none;
    }
  }
}

.ant-menu-submenu-popup {
  &.ant-menu-dark {
    .ant-menu-item-selected {
      background-color: transparent;
      color: $white;
    }
  }
}

.ant-menu-item-active,
.ant-menu-item:hover,
.ant-menu-submenu-active,
.ant-menu-submenu-title:hover,
.ant-menu:not(.ant-menu-inline) .ant-menu-submenu-open {
  color: #C60000;
}

.ant-menu-light {
  .ant-menu-submenu-open {
    >a {
      color: $brand-primary;

      .ant-menu-submenu-arrow {

        &:after,
        &:before {
          background-image: linear-gradient(to right,
              $brand-primary,
              $brand-primary) !important;
        }
      }
    }
  }
}

.ant-menu-item-selected {
  color: $brand-primary;
}

.ant-menu-submenu-selected {
  color: $brand-primary;
}

.ant-menu-dark.ant-menu-dark:not(.ant-menu-horizontal) .ant-menu-item-selected,
.ant-menu-submenu-popup.ant-menu-dark .ant-menu-item-selected,
.ant-menu.ant-menu-dark .ant-menu-item-selected {
  background-color: $brand-primary
}

.ant-menu-dark .ant-menu-sub,
.ant-menu.ant-menu-dark,
.ant-menu.ant-menu-dark .ant-menu-sub {
  background: $menu-dark-bg;
}

// .ant-menu-submenu-open{
//     background: rgba(246, 246, 246, 1);
//     border-radius: 8px;
// }

.ant-menu-inline-collapsed .ant-menu-sub.ant-menu-inline {
  padding: 10px;

}

.ant-menu-sub.ant-menu-inline>li.ant-menu-item:hover {
  background: rgb(233, 233, 233);
  border-radius: 8px;
}

.ant-menu-item-selected a {
  color: white !important;
}

.ant-menu-inline .ant-menu-submenu-title {
  margin: 0;
}

// n responsive
@media (max-width: 1366px) {

  .ant-menu-inline>.ant-menu-item,
  .ant-menu-inline>.ant-menu-submenu>.ant-menu-submenu-title,
  .ant-menu-vertical-left>.ant-menu-item,
  .ant-menu-vertical-left>.ant-menu-submenu>.ant-menu-submenu-title,
  .ant-menu-vertical-right>.ant-menu-item,
  .ant-menu-vertical-right>.ant-menu-submenu>.ant-menu-submenu-title,
  .ant-menu-vertical>.ant-menu-item,
  .ant-menu-vertical>.ant-menu-submenu>.ant-menu-submenu-title {
    line-height: $n-all-size-xs;
    height: $n-all-size-xs;
    font-size: $n-all-font-size-xs;
  }
}

li.ant-menu-submenu:hover {
  border: 2px solid #FD65AF;
}