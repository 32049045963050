.custom-gif-spinner {
  width: 100px;
  height: 100px;
  background-image: url('/src/assets/icons/icon-hailong.png');
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
}

.checkbox-zoom {
  zoom: 1.4;
}

.border-span {
  border: 1px solid #EDF2F9;
  color: #535353;
  border-radius: 50%;
  padding: 12px;
  font-size: 16px;
}

.chuThich {
  font-size: 0.8rem;
  font-style: italic;
  color: #80808096;
}

.ant-btn-green {
  background-color: #2ecc71;
  border-color: #2ecc71;
  color: white;
}

.ant-btn-blue {
  background-color: #3498db;
  border-color: #3498db;
  color: white;
}

.ant-btn-red {
  background-color: #e74c3c;
  border-color: #e74c3c;
  color: white;
}

.ant-btn-yellow {
  background-color: #f1c40f;
  border-color: #f1c40f;
  color: white;
}

.ant-btn-success {
  background-color: green;
  border-color: green;
}

.ant-btn-success:hover,
.ant-btn-success:focus {
  color: #fff;
  background-color: green;
  border-color: green;
}

.ant-btn-warring {
  background-color: orange;
  border-color: orange;
}

.ant-btn-warring:hover,
.ant-btn-warring:focus {
  color: #fff;
  background-color: orange;
  border-color: orange;
}


input:read-only {
  background-color: #f7f7f7;
}

.disableIcon {
  color: rgb(87 84 84 / 49%);
}

input::placeholder {
  opacity: 0.6;
  color: #000000 !important;
}

.ant-select-selection-placeholder {
  opacity: 0.6;
  color: #000000 !important;
}

.logo-fold {

  max-height: 50px;
  margin: auto;
}

.img-logo {
  width: 130px;
  border: none;
}

.ant-table-content .ant-tag {

  margin: 0px !important;
}

perfect-scrollbar {
  overflow: hidden;
}

.ant-radio-inner:after {
  background-color: $color-button-sb-border-color-radio;
}

.ant-radio-inner::after {
  position: absolute;
  display: block;
  width: 16px;
  height: 16px;
  border-top: 0;
  border-left: 0;
  border-radius: 16px;
  margin: -3px;
  content: ' ';
}

.n-bg-login {
  // background-image: url('/assets/images/bg.png');
  // background: url('/assets/images/PageRegister/background.png') no-repeat center center fixed;
  // -webkit-background-size: cover;
  // -moz-background-size: cover;
  // -o-background-size: cover;
  // background-size: cover;
  -webkit-background-size: contain;
  -moz-background-size: contain;
  -o-background-size: contain;
  background-size: contain;
}

/* CSS hiệu ứng bóng lướt NGHIÊNG cho logo by GIUSEART.com */

/* Sửa #logo thành phần tử CSS của bạn */
// #logo:after,
// #logo:before {
//   transform: skewX(-25deg) translateY(-50%);
//   content: "";
//   position: absolute;
//   top: 50%;
//   width: 40px;
//   height: 100%;
//   background-color: white;
//   z-index: 0
// }

// #logo:before {
//   left: 0%;
//   animation: light-left 5s infinite alternate linear
// }

// #logo:after {
//   right: -5%;
//   animation: light-right 5s infinite alternate linear
// }

// @keyframes light-left {
//   0% {
//     left: -5%;
//     opacity: 0
//   }

//   50% {
//     left: 50%;
//     opacity: 1
//   }

//   to {
//     left: 105%;
//     opacity: 0
//   }
// }

// @keyframes light-right {
//   0% {
//     right: -5%;
//     opacity: 0
//   }

//   50% {
//     right: 50%;
//     opacity: 1
//   }

//   to {
//     right: 105%;
//     opacity: 0
//   }
// }

.form-login {
  display: flex;
  width: 998px;
  min-height: 557px;
  margin-top: 92px;
  // position: absolute;
  // position: relative;
  // top: 92px;
  background-color: #fff;
  border-radius: 24px;
}

.thumbnail {
  // width: 100%;
  // height: 100%;
  // object-fit: fill;
  // margin-right: 21px
  object-fit: contain;
  height: 100%;
  width: 100%;
}

.logo {
  object-fit: fill;
}

.thumbnail-box {
  display: flex;
  margin-top: 12px;
  align-items: center;
  margin-right: 21px;
  // width: max-content;
  flex: 1;
  height: 557px;
  // height: 100%;
}

.thumbnail-box-child {
  // width: max-content;
  // height: max-content;
  width: 100%;
  height: 100%;
}

.logo-box-child {
  width: max-content;
  height: max-content;
}

.logo-box {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  // height: 100%;
}

.form-input {
  margin-top: 32px;
}

.form-main {
  flex: 1;
  padding: 41px 52px 0 52px
}

.title-input {
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  color: #444444;
  margin-bottom: 0;
}

.title-1 {
  font-weight: 400;
  font-size: 24px;
  line-height: 29px;
  color: #000;
  margin-bottom: 0;
}

.title-2 {
  padding-top: 12px;
  padding-bottom: 24px;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  color: #909090;
  margin-bottom: 0;
}

.login-form-button {
  background-color: #1E86FF;
  color: #FFFFFF;
  font-size: 13px;
  font-weight: 400;
  line-height: 16px;
  border: unset;
  border-radius: 4px;
  width: 115px;
  height: 40px;
}

.form-login-password-input {
  margin-bottom: 10px;
}

.forgot-pass {
  color: #1E86FF;
  font-size: 13px;
  font-weight: 400;
  line-height: 16px;
}

.login-form-button:hover {
  opacity: 0.7;
  cursor: pointer;
}

// .footer {
//   width: 998px;
//   display: flex;
//   justify-content: space-between;
// }


.content-container {
  position: relative;

  overflow: auto;
  height: 300px;
  min-height: 0;
  border-radius: 4px;
  background-color: #fff;
}

.ant-form-item-label {
  white-space: normal;
}

.cursor-pointer :hover {
  cursor: pointer;
}

.theLan {
  background: radial-gradient(116.77% 322.34% at 109.25% 68.49%, #F28300 0%, #FF39EB 100%);
  color: white;
  border-radius: 8px;
}

.theTien {
  background: radial-gradient(116.77% 322.34% at 109.25% 68.49%, #0052F2 0%, #00F462 100%);
  color: white;
  border-radius: 8px;
}

.link-router {
  height: 40px;
  display: flex;
  flex-direction: column;
  align-items: end;
  justify-content: space-between;
}

/* width */
::-webkit-scrollbar {
  width: 7px;
  cursor: pointer;
  height: 7px;
}

/* Track */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px #ddd;
  border-radius: 3px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #ccc;
  border-radius: 3px;
  opacity: 0.01;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #f89854;
}

.choNgoi .ant-select-arrow {
  left: 70% !important;
}

.choNgoi .ant-select-clear {
  left: 70% !important;
}

.input-phuthu-thuevat .ant-input {
  padding: 0rem 0rem;
}

.tabset-custom .ant-tabs-nav {
  margin: 0 0 0px !important;
}

@media screen and (max-width: 1367px) {
  .form-login {
    margin-top: 50px;
  }
}

@media screen and (max-width: 1024px) {
  .form-login {
    width: 80%;
    margin: 50px
  }

  .thumbnail-box {
    width: 300px
  }

  .thumbnail-box-child {
    width: 300px
  }

  .thumbnail {
    width: 300px
  }
}


@media screen and (max-width: 850px) {
  .form-login {
    width: 80%;
    margin: 50px;
  }

  .form-main {
    flex: 1;
    padding: 41px 53px 0 53px
  }

  .thumbnail-box {
    display: none;
  }
}

@media screen and (max-width: 768px) {
  .form-login {
    width: 70%;
    margin: 50px;
  }

  .form-main {
    flex: 1;
    padding: 41px 53px 0 53px
  }

  .thumbnail-box {
    display: none;
  }
}

@media screen and (max-width: 600px) {
  .form-login {
    width: 80%;
    margin: 50px;
  }

  .form-main {
    flex: 1;
    padding: 41px 25px 0 25px
  }


}




@media screen and (max-width: 440px) {
  .form-login {
    width: 90%;
    height: max-content;
    // margin: 10px
  }

  .form-input {
    margin-top: 20px
  }

  .form-main {
    flex: 1;
    padding: 20px 20px 20px 20px
  }

  .thumbnail-box {
    display: none;
  }

  .title-input {
    font-size: 10px;
  }

  .title-1 {
    font-size: 16px;
  }

  .title-2 {
    font-size: 10px;
  }

  .login-form-button {
    font-size: 10xp;
    height: 40px;
    width: 100px;
  }

  .forgot-pass {
    font-size: 10px;
  }
}

.input-type .ant-input {
  text-align: center;
}

.tn-badge .ant-badge-count {
  right: -5px;
}








@media screen and (max-width: 991px) {
  .m-b-md-10 {
    margin-bottom: 10px;
  }

}

@media screen and (max-width: 768px) {
  .m-b-sm-10 {
    margin-bottom: 10px;
  }
}

@media screen and (max-width: 576px) {
  .m-b-xs-10 {
    margin-bottom: 10px;
  }
}


// only for desktop 1920
// @media  ( min-width:1701px) {
//   .n-tabset-custom{
//     .ant-tabs-nav {
//       margin: 0 0 0px !important;
//       height: 40px;
//     }

// }
// }
// :ng-deep {
//  @media ( min-width:1701px) {
//   .n-tabset-custom{
//     .ant-tabs-nav {
//       margin: 0  !important;
//       height: 40px;
//     }
//     .ant-tabs-tab{
//       line-height: 37px!important;
//     }
//     .ant-tabs.ant-tabs-card > .ant-tabs-nav .ant-tabs-tab{
//       line-height: 37px!important;

//     }
//     .ant-tabs-tab-btn {
//       line-height: 40px;
//   }
//   }
//  }
// }
//end only for desktop

#check {
  display: none;
}

.box-to-guide {
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 54px;
  height: 54px;
  position: fixed;
  right: 17px;
  background: #15397f;
  border-radius: 100%;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.35);
  z-index: 100;
  color: #fff !important;
  bottom: 38px;
  -webkit-transition: all 0.25s ease;
  -moz-transition: all 0.25s ease;
  transition: all 0.25s ease;

  .small-text {
    font-size: 10px;
    color: #fff !important;
    margin-top: -4px;
  }
}

.box-icon-bar {
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 56px;
  position: fixed;
  height: 56px;
  bottom: 37px;
  right: 16px;
  background: #15397f;
  border-radius: 100%;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.35);
  z-index: 2147483647;
  color: #fff !important;

  .small-text {
    font-size: 10px;
    color: #fff !important;
    margin-top: -4px;
  }
}

#vnp_chat {
  // body{
  //   overflow: hidden!important;
  // }
  // html{
  //   overflow: hidden!important;
  // }
  // *{
  //   overflow: hidden!important;
  // }
  z-index: 100 !important;
  position: fixed;
  height: 90px;
  width: 90px;
  bottom: 20px !important;
  right: -1px !important;
  visibility: unset !important;
  top: unset !important;
  right: 0px;
  -webkit-transition: all 0.25s ease;
  -moz-transition: all 0.25s ease;
  transition: all 0.25s ease;
}

#check:checked~#vnp_chat {
  // top: 52px;
  z-index: 100;
  bottom: 91px !important;
  -webkit-transition: all(0.1s) ease;
  -moz-transition: all(0.1s) ease;
  transition: all(0.1s) ease;
}

#check:checked~.box-to-guide {
  // right: 30px;
  bottom: 180px !important;
  z-index: 100;
  -webkit-transition: all(0.1s) ease;
  -moz-transition: all(0.1s) ease;
  transition: all(0.1s) ease;
}

#check:checked~app-root .omi-button {
  // right: 30px;
  bottom: 245px !important;
  z-index: 100;
  -webkit-transition: all(0.1s) ease;
  -moz-transition: all(0.1s) ease;
  transition: all(0.1s) ease;
  width: 60px;
  height: 60px;
}

//Duy fix khi thêm lazy load
// .table-container {
//   overflow-x: auto;
// }

.nz-table-custom {
  th {
    cursor: pointer;
  }

  // cursor: pointer
}


.button-call-in-form:hover {
  cursor: pointer;
  opacity: 0.5;
}

.selected-nhomsp-backgroup {
  background: linear-gradient(to right, #FE8377, #FD65AF) !important;
}

.active-button {
  background: $color-button-sb !important;
  color: white;
}

// .ant-btn {
//   background: linear-gradient(to right, #FE8377, #FD65AF) ;
//   color: rgba(255, 255, 255, 1);
//   border-color: #3f87f500;
// }
.text-color-orange {
  color: darkorange;
}

.text-color-blue {
  color: #007bff;
}

.text-color-sb {
  color: #FD65AF;
}

.selected-nhomsp-backgroup {
  background: linear-gradient(to right, #FE8377, #FD65AF) !important;
}

.n-bg-color-sb {
  background: linear-gradient(to right, #FE8377, #FD65AF);
  color: white;
}


.active-button {
  background: $color-button-sb !important;
  color: white;
}

// .ant-btn {
//   background: linear-gradient(to right, #FE8377, #FD65AF) ;
//   color: rgba(255, 255, 255, 1);
//   border-color: #3f87f500;
// }
gridster {
  background: #f9fbfd !important;
}

.box-bc-editing {
  gridster {
    background: gray !important;
  }

}

sb-menu-tab {
  display: flex;
}

.gridster-item-moving .gridster-item-resizable-handler.handle-se {
  border-color: transparent transparent #ccc;
}

.gridster-item-resizing .gridster-item-resizable-handler.handle-se {
  border-color: transparent transparent #ccc;
}


// resize table
th,
td {
  padding: 8px;
  position: relative;
}

th {
  user-select: none;
  position: relative;
}

.resizer {
  position: absolute;
  top: 0;
  right: -2px;
  width: 3px;
  height: 100%;
  cursor: col-resize;
  z-index: 1;
}

#guide-line {
  position: absolute;
  top: 0;
  bottom: 0;
  width: 2px;
  background: linear-gradient(to right, #c03c30, #bd2870);
  display: none;
  z-index: 1000;
}

.guide-line {
  position: absolute;
  top: 0;
  bottom: 0;
  width: 2px;
  background: linear-gradient(to right, #c03c30, #bd2870);
  display: none;
  z-index: 1000;
}

////
///
// Define variables for colors
$primary-bg: #E3F3FF;
$primary-bar: #47AEFC;

$primarydarker-bg: #E3F3FF;
$primarydarker-bar: #1A95FF;

$primary4b-bg: #E3F3FF;
$primary4b-bar: #4B9AD6;

$primary4e-bg: #E7F8FF;
$primary4e-bar: #4ECAFF;

$darkblue-bg: #E8EDFF;
$darkblue-bar: #215FFF;

$warning-bg: #FFF8E1;
$warning-bar: #FFC30E;


$warningdarker-bg: #FFF8E1;
$warningdarker-bar: #FFA500;

$info-bg: #DAF6F8;
$info-bar: #16C3C3;

$infodarker-bg: #DAF6F8;
$infodarker-bar: #00A3A3;

$danger-bg: #FFE4E4;
$danger-bar: #FF4B4B;

$green-bg: #F0FFEF;
$green-bar: #56C74C;

$greendarker-bg: #F0FFEF;
$greendarker-bar: #38A028;

$lightgreen-bg: #F2FBE8;
$lightgreen-bar: #9FCF62;

$lightyellow-bg: #FFF8E1;
$lightyellow-bar: #FEDA59;

$lightblue-bg: #E1F4F1;
$lightblue-bar: #70DFDF;

$orange-bg: #FFE5CB;
$orange-bar: #FF942A;

$orangedarker-bg: #FFE5CB;
$orangedarker-bar: #FF6D00;

$darkgreen-bg: #F2FBE8;
$darkgreen-bar: #00B796;

$pink-bg: #FFECF5;
$pink-bar: #FB70B2;

$brown-bg: #FFE9E9;
$brown-bar: #CD6F6F;

$darkpurple-bar: #7f5de5;

$lightpurple-bar: #d85efe;

// Apply styles using SCSS nesting and variables
.n-bg-progress {
  &-primary {
    .progress {
      background-color: $primary-bg;
    }

    .progress-bar {
      background: $primary-bar;
    }
  }

  &-primarydarker {
    .progress {
      background-color: $primarydarker-bg;
    }

    .progress-bar {
      background: $primarydarker-bar;
    }
  }

  &-primary4b {
    .progress {
      background-color: $primary4b-bg;
    }

    .progress-bar {
      background: $primary4b-bar;
    }
  }

  &-primary4e {
    .progress {
      background-color: $primary4e-bg;
    }

    .progress-bar {
      background: $primary4e-bar;
    }
  }

  &-warning {
    .progress {
      background: $warning-bg;
    }

    .progress-bar {
      background: $warning-bar;
    }
  }

  &-warningdarker {
    .progress {
      background: $warningdarker-bg;
    }

    .progress-bar {
      background: $warningdarker-bar;
    }
  }

  &-info {
    .progress {
      background: $info-bg;
    }

    .progress-bar {
      background: $info-bar;
    }
  }

  &-infodarker {
    .progress {
      background: $infodarker-bg;
    }

    .progress-bar {
      background: $infodarker-bar;
    }
  }

  &-danger {
    .progress {
      background: $danger-bg;
    }

    .progress-bar {
      background: $danger-bar;
    }
  }

  &-green {
    .progress {
      background: $green-bg;
    }

    .progress-bar {
      background: $green-bar;
    }
  }

  &-greendarker {
    .progress {
      background: $greendarker-bg;
    }

    .progress-bar {
      background: $greendarker-bar;
    }
  }

  &-lightgreen {
    .progress {
      background: $lightgreen-bg;
    }

    .progress-bar {
      background: $lightgreen-bar;
    }
  }

  &-lightyellow {
    .progress {
      background: $lightyellow-bg;
    }

    .progress-bar {
      background: $lightyellow-bar;
    }
  }

  &-lightblue {
    .progress {
      background: $lightblue-bg;
    }

    .progress-bar {
      background: $lightblue-bar;
    }
  }

  &-orange {
    .progress {
      background: $orange-bg;
    }

    .progress-bar {
      background: $orange-bar;
    }
  }

  &-orangedarker {
    .progress {
      background: $orangedarker-bg;
    }

    .progress-bar {
      background: $orangedarker-bar;
    }
  }

  &-darkblue {
    .progress {
      background: $darkblue-bg;
    }

    .progress-bar {
      background: $darkblue-bar;
    }
  }

  &-pink {
    .progress {
      background: $pink-bg;
    }

    .progress-bar {
      background: $pink-bar;
    }
  }

  &-brown {
    .progress {
      background: $brown-bg;
    }

    .progress-bar {
      background: $brown-bar;
    }
  }

  &-darkgreen {
    .progress {
      background: $darkgreen-bg;
    }

    .progress-bar {
      background: $darkgreen-bar;
    }
  }
}


.n-bg-primary {
  background-color: $primary-bar;
}

.n-bg-warning {
  background-color: $warning-bar;
}

.n-bg-info {
  background-color: $info-bar;
}

.n-bg-danger {
  background-color: $danger-bar;
}

.n-bg-green {
  background-color: $green-bar;
}

.n-bg-lightgreen {
  background-color: $lightgreen-bar;
}

.n-bg-lightyellow {
  background-color: $lightyellow-bar;
}

.n-bg-lightblue {
  background-color: $lightblue-bar;
}

.n-bg-orange {
  background-color: $orange-bar;
}

.n-bg-darkblue {
  background-color: $darkblue-bar;
}

.n-bg-darkgreen {
  background-color: $darkgreen-bar;
}

.n-bg-pink {
  background-color: $pink-bar;
}

.n-bg-brown {
  background-color: $brown-bar;
}

.n-bg-dark-purple {
  background-color: $darkpurple-bar;
}

.n-bg-light-purple {
  background-color: $lightpurple-bar;
}


.n-color-primary {
  color: $primary-bar;
}

.n-color-warning {
  color: $warning-bar;
}

.n-color-info {
  color: $info-bar;
}

.n-color-danger {
  color: $danger-bar;
}

.n-color-green {
  color: $green-bar;
}

.n-color-lightgreen {
  color: $lightgreen-bar;
}

.n-color-lightyellow {
  color: $lightyellow-bar;
}

.n-color-lightblue {
  color: $lightblue-bar;
}

.n-color-orange {
  color: $orange-bar;
}

.n-color-darkblue {
  color: $darkblue-bar;
}

.n-color-darkgreen {
  color: $darkgreen-bar;
}

.n-color-pink {
  color: $pink-bar;
}

.n-color-brown {
  color: $brown-bar;
}

.n-color-dark-purple {
  color: $darkpurple-bar;
}

.n-color-light-purple {
  color: $lightpurple-bar;
}


.sb-nz-select-multi-custom {
  .ant-select-selector {
    height: 32px;
    min-height: 32px !important;

    .ant-select-selection-item {
      height: 22px;
      line-height: 22px;
      margin: 4px;
      max-width: 60%;
    }
  }
}

.text-inline-td-ellipsis {
  max-width: inherit;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  display: block;
  // width: inherit;
}

::ng-deep .ant-form-item-label {
  overflow: unset;
}

.table-action-button:hover {
  cursor: pointer;
  opacity: 0.7;
}

//Hight light
@mixin highLightSuccess {
  background-color: #fff;
  box-shadow: inset 0 1em 1em -1em #28a745, inset 0 -1em 1em -1em #28a745;
  transition: ease 3s all;
}

@mixin highLightWarning {
  background-color: #fff;
  box-shadow: inset 0 1em 1em -1em #ffc107, inset 0 -1em 1em -1em #ffc107;
  transition: ease 3s all;
}

@mixin highLightDanger {
  background-color: #fff;
  box-shadow: inset 0 1em 1em -1em #dc3545, inset 0 -1em 1em -1em #dc3545;
  transition: ease 3s all;
}

.high-light-success {
  @include highLightSuccess;

  .ant-table-cell-fix-left,
  .ant-table-cell-fix-right {
    @include highLightSuccess;
  }
}

.high-light-success:hover {
  @include highLightSuccess;

  td {
    @include highLightSuccess;
  }
}

.high-light-warning {
  @include highLightWarning;

  .ant-table-cell-fix-left,
  .ant-table-cell-fix-right {
    @include highLightWarning;
  }
}

.high-light-warning:hover {
  @include highLightWarning;

  td {
    @include highLightWarning;
  }
}

.high-light-danger {
  @include highLightDanger;

  .ant-table-cell-fix-left,
  .ant-table-cell-fix-right {
    @include highLightDanger;
  }
}

.high-light-danger:hover {
  @include highLightDanger;

  td {
    @include highLightDanger;
  }
}

//end hight light

.nz-table-hide-scrollbar {
  overflow: hidden !important;
}


.tabset-custom {
  .ant-tabs-nav {

    .ant-tabs-nav-wrap {
      flex: 1;
    }

    .ant-tabs-nav-operations {
      width: 48px;
      border: 1px solid #ddd;

      .ant-tabs-nav-more {
        padding: 5px 16px;
      }
    }
  }
}

.ng-dropdown-panel-items {

  font-size: 13px;
}

.custom-nz-select-dropdown {
  .ant-select-item-option-content {
    display: block;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 500px;
  }
}

.custom-time-picker-dropdown {
  .ant-picker-now {
    display: none !important;
  }
}