.sb-icon {
  color: #fff;
  display: inline-block;
  align-items: center;
  justify-content: center;
  height: 16px;
  width: 16px;
  cursor: pointer;
  margin-right: 5px;
  background: url(/assets/icons/icon-button.png) 0px 0px;
  vertical-align: -0.27em;
  transition: background-color 0.3s ease, transform 0.2s ease, opacity 0.2s ease;
}

/* Hiệu ứng hover */
.sb-icon:hover {
  opacity: 0.7;
}

/* Hiệu ứng khi click vào */
.sb-icon:active {
  transform: scale(0.85);
}

$icon-size-left: 16px;

.sb-icon-load-circle {
  @extend .sb-icon;
  background-position-x: calc(#{$icon-size-left * -1} * 0);
}

.sb-icon-copy {
  @extend .sb-icon;
  background-position-x: calc(#{$icon-size-left * -1} * 1);
}

.sb-icon-phone {
  @extend .sb-icon;
  background-position-x: calc(#{$icon-size-left * -1} * 2);
}

.sb-icon-mic {
  @extend .sb-icon;
  background-position-x: calc(#{$icon-size-left * -1} * 3);
}

.sb-icon-down {
  @extend .sb-icon;
  background-position-x: calc(#{$icon-size-left * -1} * 4);
}

.sb-icon-message {
  @extend .sb-icon;
  background-position-x: calc(#{$icon-size-left * -1} * 5);
}

.sb-icon-eye {
  @extend .sb-icon;
  background-position-x: calc(#{$icon-size-left * -1} * 6);
}

.sb-icon-heart {
  @extend .sb-icon;
  background-position-x: calc(#{$icon-size-left * -1} * 7);
}

.sb-icon-list {
  @extend .sb-icon;
  background-position-x: calc(#{$icon-size-left * -1} * 8);
}

.sb-icon-dislike {
  @extend .sb-icon;
  background-position-x: calc(#{$icon-size-left * -1} * 9);
}

.sb-icon-truck {
  @extend .sb-icon;
  background-position-x: calc(#{$icon-size-left * -1} * 10);
}

.sb-icon-history {
  @extend .sb-icon;
  background-position-x: calc(#{$icon-size-left * -1} * 11);
}

.sb-icon-check-list {
  @extend .sb-icon;
  background-position-x: calc(#{$icon-size-left * -1} * 12);
}

.sb-icon-setting {
  @extend .sb-icon;
  background-position-x: calc(#{$icon-size-left * -1} * 13);
}

.sb-icon-more {
  @extend .sb-icon;
  background-position-x: calc(#{$icon-size-left * -1} * 14);
}

.sb-icon-printer {
  @extend .sb-icon;
  background-position-x: calc(#{$icon-size-left * -1} * 15);
}

.sb-icon-save {
  @extend .sb-icon;
  background-position-x: calc(#{$icon-size-left * -1} * 16);
}

.sb-icon-search {
  @extend .sb-icon;
  background-position-x: calc(#{$icon-size-left * -1} * 17);
}

.sb-icon-search-btn {
  @extend .sb-icon;
  background-position-x: calc(#{$icon-size-left * -1} * 18);
}

.sb-icon-people-btn {
  @extend .sb-icon;
  background-position-x: calc(#{$icon-size-left * -1} * 19);
}

.sb-icon-global-btn {
  @extend .sb-icon;
  background-position-x: calc(#{$icon-size-left * -1} * 20);
}

.sb-icon-shop-btn {
  @extend .sb-icon;
  background-position-x: calc(#{$icon-size-left * -1} * 21);
}

.sb-icon-copy-btn {
  @extend .sb-icon;
  background-position-x: calc(#{$icon-size-left * -1} * 22);
}

.sb-icon-bell-btn {
  @extend .sb-icon;
  background-position-x: calc(#{$icon-size-left * -1} * 23);
}

.sb-icon-left {
  @extend .sb-icon;
  background-position-x: calc(#{$icon-size-left * -1} * 24);
}

.sb-icon-right {
  @extend .sb-icon;
  background-position-x: calc(#{$icon-size-left * -1} * 25);
}

.sb-icon-left-btn {
  @extend .sb-icon;
  background-position-x: calc(#{$icon-size-left * -1} * 26);
}

.sb-icon-right-btn {
  @extend .sb-icon;
  background-position-x: calc(#{$icon-size-left * -1} * 27);
}

.sb-icon-info {
  @extend .sb-icon;
  background-position-x: calc(#{$icon-size-left * -1} * 28);
}

.sb-icon-edit-btn {
  @extend .sb-icon;
  background-position-x: calc(#{$icon-size-left * -1} * 29);
}

.sb-icon-edit {
  @extend .sb-icon;
  background-position-x: calc(#{$icon-size-left * -1} * 30);
}

.sb-icon-plus-circle {
  @extend .sb-icon;
  background-position-x: calc(#{$icon-size-left * -1} * 31);
}

.sb-icon-calendar {
  @extend .sb-icon;
  background-position-x: calc(#{$icon-size-left * -1} * 32);
}

.sb-icon-calendar-2 {
  @extend .sb-icon;
  background-position-x: calc(#{$icon-size-left * -1} * 33);
}


.sb-icon-plus {
  @extend .sb-icon;
  background-position-x: calc(#{$icon-size-left * -1} * 34);
}

.sb-icon-bomb {
  @extend .sb-icon;
  background-position-x: calc(#{$icon-size-left * -1} * 35);
}

.sb-icon-delete {
  @extend .sb-icon;
  background-position-x: calc(#{$icon-size-left * -1} * 36);
}


.sb-icon-history-2 {
  @extend .sb-icon;
  background-position-x: calc(#{$icon-size-left * -1} * 37);
}

.sb-icon-circle-up {
  @extend .sb-icon;
  background-position-x: calc(#{$icon-size-left * -1} * 38);
}

.sb-icon-circle-down {
  @extend .sb-icon;
  background-position-x: calc(#{$icon-size-left * -1} * 39);
}

.sb-icon-bomb-2 {
  @extend .sb-icon;
  background-position-x: calc(#{$icon-size-left * -1} * 40);
}

.sb-icon-clock {
  @extend .sb-icon;
  background-position-x: calc(#{$icon-size-left * -1} * 41);
}

.sb-icon-import {
  @extend .sb-icon;
  background-position-x: calc(#{$icon-size-left * -1} * 42);
}

.sb-icon-export {
  @extend .sb-icon;
  background-position-x: calc(#{$icon-size-left * -1} * 43);
}

.sb-icon-history-3 {
  @extend .sb-icon;
  background-position-x: calc(#{$icon-size-left * -1} * 44);
}

.sb-icon-load-circle-2 {
  @extend .sb-icon;
  background-position-x: calc(#{$icon-size-left * -1} * 45);
}

.sb-icon-branch {
  @extend .sb-icon;
  background-position-x: calc(#{$icon-size-left * -1} * 46);
}

.sb-icon-calendar-edit {
  @extend .sb-icon;
  background-position-x: calc(#{$icon-size-left * -1} * 47);
}

.sb-icon-calendar-plus {
  @extend .sb-icon;
  background-position-x: calc(#{$icon-size-left * -1} * 48);
}

.sb-icon-update-care {
  @extend .sb-icon;
  background-position-x: calc(#{$icon-size-left * -1} * 49);
}

.sb-icon-update-ship {
  @extend .sb-icon;
  background-position-x: calc(#{$icon-size-left * -1} * 50);
}

.sb-icon-update {
  @extend .sb-icon;
  background-position-x: calc(#{$icon-size-left * -1} * 51);
}


.sb-icon-edit-green {
  @extend .sb-icon;
  background-position-x: calc(#{$icon-size-left * -1} * 52);
}