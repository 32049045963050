body {
  font-family: $font-family-base;
  background-color: $body-bg;
  color: $body-color;
}

a {
  color: $link-color;

  &:active,
  &:hover {
    color: $link-hover-color;
  }

  &:focus {
    text-decoration: none;
  }

  &.text-gray,
  &.text-muted {

    &:hover {
      color: $gray-dark !important;
    }
  }

  &.text-dark {

    &:hover {
      color: $link-hover-color !important;
    }
  }

  &.file {
    &:hover {
      background-color: #f9fbfd;
    }
  }
}

hr {
  border: 0px;
  border-top: 1px solid $border-color;
  margin-top: 2rem;
  margin-bottom: 2rem;
}

code {
  margin: 0 1px;
  background: $gray-lightest;
  padding: .2em .4em;
  border-radius: 3px;
  font-size: .9em;
  border: 1px solid $border-color;
  color: $brand-danger
}

h4 {
  margin-bottom: 0px !important;
}