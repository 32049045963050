@import 'variables';
@import 'mixins';
@import 'reset';


/*******************/
/*** Components ***/
/*****************/

/*General*/
@import 'components/general/typorgraphy';
@import 'components/general/buttons';
@import 'components/general/icons';

/*Layout*/
@import 'components/layout/breakpoint';
@import 'components/layout/grid';

/*Navigation*/
@import 'components/navigation/_affix';
@import 'components/navigation/_breadcrumb';
@import 'components/navigation/dropdown';
@import 'components/navigation/menu';
@import 'components/navigation/pagination';
@import 'components/navigation/page-header';
@import 'components/navigation/steps';

/*Data Entry*/
@import 'components/data-entry/autocomplete';
@import 'components/data-entry/cascader';
@import 'components/data-entry/checkbox';
@import 'components/data-entry/date-picker';
@import 'components/data-entry/form';
@import 'components/data-entry/input';
@import 'components/data-entry/mention';
@import 'components/data-entry/radio';
@import 'components/data-entry/rate';
@import 'components/data-entry/select';
@import 'components/data-entry/slider';
@import 'components/data-entry/switch';
@import 'components/data-entry/time-picker';
@import 'components/data-entry/transfer';
@import 'components/data-entry/tree-select';
@import 'components/data-entry/upload';

/*Data Display*/
@import 'components/data-display/avatar';
@import 'components/data-display/badge';
@import 'components/data-display/calendar';
@import 'components/data-display/card';
@import 'components/data-display/carousel';
@import 'components/data-display/collapse';
@import 'components/data-display/comment';
@import 'components/data-display/descriptions';
@import 'components/data-display/list';
@import 'components/data-display/popover';
@import 'components/data-display/statistic';
@import 'components/data-display/table';
@import 'components/data-display/tabs';
@import 'components/data-display/tag';
@import 'components/data-display/timeline';
@import 'components/data-display/tooltip';
@import 'components/data-display/tree';

/*Feedback*/
@import 'components/feedback/alert';
@import 'components/feedback/drawer';
@import 'components/feedback/message';
@import 'components/feedback/modal';
@import 'components/feedback/notifications';
@import 'components/feedback/progress';
@import 'components/feedback/spin';

/*Other*/
@import 'components/others/anchor';
@import 'components/others/back-to-top';
@import 'components/others/divider';

/******************/
/*** Template ****/
/****************/
@import 'template/header.scss';
@import 'template/layout.scss';
@import 'template/side-nav.scss';
@import 'template/footer.scss';

/*****************/
/***** Apps *****/
/***************/
@import 'apps/chat.scss';
@import 'apps/file-manager.scss';
@import 'apps/mail.scss';
@import 'apps/e-commerce.scss';

/*****************/
/*** Plugins ****/
/***************/
@import 'plugins/chartist.scss';
@import 'plugins/quill.scss';

/*******************/
/*** Utilities ****/
/*****************/
@import 'utilities/utilities-bs.scss';
@import 'utilities/utilities-enlink.scss';


/*******************/
/****** Demo ******/
/*****************/




@import 'icon.scss';


@import 'demo/demo';

.full-height {
  min-height: 100vh;
}

.loading {
  color: white;
  padding-top: 50px;
}

span.color-gray {
  color: #919191;
}

//tooltip
.tooltip-custom:before {
  content: attr(data-text);
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 100%;
  margin-left: 15px;
  width: 200px;
  padding: 10px;
  border-radius: 10px;
  background: #000;
  color: #fff;
  text-align: center;
  z-index: 1;
  display: none;
}


.tooltip-custom {
  position: relative;
}

.tooltip-custom:hover:before {
  display: block;
}

.tooltip-custom:after {
  content: "";
  position: absolute;
  left: 100%;
  margin-left: -4px;
  top: 50%;
  transform: translateY(-50%);
  border: 10px solid #000;
  border-color: transparent black transparent transparent;
  display: none;
}

.tooltip-custom:hover:before,
.tooltip-custom:hover:after {
  display: block;
}

.tooltip-custom:before {
  opacity: 0;
  transition: .3s opacity;
}

.tooltip-custom:hover:before {
  opacity: 1;
}

.tooltip-custom:after {
  opacity: 0;
  transition: .3s;
}

.tooltip-custom:hover:after {
  opacity: 1;
}


// button iscolpase
.button-group {
  .button-icon {
    width: 47px;
    // background-color: darken(#4087f5, 10%);
    background: linear-gradient(to right, #FD65AF, #FE8377);
    color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 15px;
    height: $n-all-size-sm;

  }

  .button-icon.button-right {
    border-top-right-radius: 6px;
    border-end-end-radius: 6px;
  }

  .button-icon.button-left {
    border-top-left-radius: 6px;
    border-end-start-radius: 6px;
  }

  .icon-down-filter {
    font-size: 15px;
    margin-left: 8px
  }
}


.button-group-small {
  .button-icon {
    width: 35px;
    background: $color-button-sb;
    color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 12px;
    height: $n-all-size-sm;

  }

  .button-icon.button-right {
    border-top-right-radius: 6px;
    border-end-end-radius: 6px;
  }

  .button-icon.button-left {
    border-top-left-radius: 6px;
    border-end-start-radius: 6px;
  }

  .icon-down-filter {
    font-size: 12px;
    margin-left: 8px
  }

  .ant-btn-sm {
    font-size: 12px;
  }
}

.button-custom.ant-btn {
  border-radius: unset;
  padding: unset;
  padding: 4px 15px;
}

.button-custom-only.ant-btn {
  border-radius: 6px;
}

.button-left.ant-btn {
  border-top-left-radius: 6px;
  border-end-start-radius: 6px;
}


.button-right.ant-btn {
  border-top-right-radius: 6px;
  border-end-end-radius: 6px;
}



.button-group>.button-child:nth-child(2) {
  margin-left: 10px;
}

.button-group-small>.button-child:nth-child(2) {
  margin-left: 10px;
}


.filter-expands {
  margin-top: 7px !important;
}

.menu-dropdown {
  width: 100%;
  height: 35px;
  display: flex;
  justify-content: space-between;
  padding: 0 0 0 10px;
}

.button-delete-filter {
  height: 100%;
  aspect-ratio: 1 / 1;
  color: #E74C3C;
  display: flex;
  justify-content: center;
  align-items: center;
  // border-radius: 5px;
}

.button-delete-filter:hover {
  cursor: pointer;
  opacity: 0.5;
}

.button-icon:hover {
  cursor: pointer;
  opacity: 0.8;
}

// n responsive
@media (max-width: 1366px) {
  .button-group {
    .button-icon {
      width: 47px;
      // background-color: darken(#4087f5, 10%);
      background: linear-gradient(to right, #FD65AF, #FE8377);
      color: #fff;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: $n-all-font-size-xs;
      height: $n-all-size-xs;

    }

  }

  .button-group-small {
    .button-icon {
      width: 35px;
      background: $color-button-sb;
      color: #fff;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: $n-all-font-size-xs;
      height: $n-all-size-xs;
    }
  }
}